/**
 * Created by Reda on 02.11.2024
 */

var BoosterOfferLogic = function (offer) {
    cleverapps.EventEmitter.call(this);
    this.offer = offer;
};

BoosterOfferLogic.prototype = Object.create(cleverapps.EventEmitter.prototype);
BoosterOfferLogic.prototype.constructor = BoosterOfferLogic;

BoosterOfferLogic.prototype.getTimeLeft = function () {
    return undefined;
};

BoosterOfferLogic.prototype.isIconAvailable = function () {
    return true;
};

BoosterOfferLogic.prototype.hasIconAttention = function () {
    return false;
};

BoosterOfferLogic.prototype.getProduct = function () {
    return Product.CreateById("kit3");
};

BoosterOfferLogic.prototype.processBought = function () {
    new RewardWindow(Offers[this.offer.type].reward, { event: cleverapps.EVENTS.EARN.PURCHASE });

    cleverapps.eventLogger.logEvent(this.offer.name + "_" + cleverapps.EVENTS.OFFER_LOT);

    this.offer.complete();
};