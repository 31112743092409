/**
 * Created by Reda on 02.11.2024
 */

var BoosterOfferWindow = CleverappsWindow.extend({
    onWindowLoaded: function (offer) {
        this.logic = offer.logic;

        var pack = this.logic.getProduct();

        var styles = cleverapps.styles.BoosterOfferWindow;

        var button = styles.button;
        button.text = pack.getCurrentPrice();
        button.onPressed = function () {
            this.buyProduct();
        }.bind(this);

        var content = this.generateContent(pack);

        this._super({
            name: "BoosterOfferWindow",
            title: "BoosterOffer.title",
            content: content,
            button: button,
            styles: styles,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true
        });

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);

        this.updateButton();
    },

    updateButton: function () {
        if (connector.payments.isConnected()) {
            this.buttons.enable();
        } else {
            this.buttons.disable();
        }
    },

    onShow: function () {
        this._super();

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.1),
            new cc.PlaySound(bundles.booster_offer.urls.booster_offer_effect)
        ));
    },

    buyProduct: function () {
        var product = this.logic.getProduct();

        product.buy(function (success) {
            if (success) {
                if (!this.closed) {
                    this.close();
                }
                this.logic.processBought();
            }
        }.bind(this));
    },

    generateContent: function () {
        var styles = cleverapps.styles.BoosterOfferWindow;

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setPositionRound(styles.position);

        var animation = content.animation = new cleverapps.Spine(bundles.booster_offer.jsons.booster_offer_json);
        animation.setAnimation(0, "animation", true);
        content.addChild(animation);
        content.setContentSize2(styles.size);
        animation.setPositionRound(styles.animation.position);

        var text = cleverapps.UI.generateOnlyText(
            "BoosterOffer.description",
            cleverapps.styles.FONTS.WHITE_TITLE_TEXT
        );

        content.addChild(text);
        text.setPositionRound(styles.text.position);
        text.setWrapWidth(styles.text.wrapWidth);
        return content;
    }
});

BoosterOfferWindow.prototype.listBundles = function () {
    return ["booster_offer"];
};

cleverapps.styles.BoosterOfferWindow = {
    position: {
        x: {
            align: "center",
            dx: 0
        },
        y: {
            align: "center",
            dy: 0
        }
    },
    noBackground: true,
    size: {
        width: 700,
        height: 800
    },
    button: {
        width: 300,
        height: 100,
        x: 0,
        y: 50
    },
    animation: {
        position: {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "top",
                dy: -80
            }
        }
    },
    text: {
        position: {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "bottom",
                dy: 0
            }
        },
        wrapWidth: 700
    }
};
